import { createStore } from 'vuex';

export default createStore({
  state: {
    breadcrumbs: []
  },
  mutations: {
    SET_BREADCRUMBS(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    ADD_BREADCRUMB(state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb);
    },
    RESET_BREADCRUMBS(state) {
      state.breadcrumbs = [];
    }
  },
  actions: {
    setBreadcrumb({ commit }, breadcrumbs) {
      commit('SET_BREADCRUMBS', breadcrumbs);
    },
    addBreadcrumb({ commit }, breadcrumb) {
      commit('ADD_BREADCRUMB', breadcrumb);
    },
    resetBreadcrumb({ commit }) {
      commit('RESET_BREADCRUMBS');
    }
  },
  getters: {
    breadcrumbs: (state) => state.breadcrumbs
  }
});
